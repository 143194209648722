import { Listing, ListingsObject } from '../../types'

const API_URL = 'https://a1kf0q8cnh.execute-api.eu-central-1.amazonaws.com'

export default async function getListings(): Promise<ListingsObject> {
  try {
    const response = await fetch(`${API_URL}/api/listings`)

    // TODO:: Make sure the remote listings actually have the Listing fields
    const listings: Array<Listing> = await response.json()
    console.log(listings)

    const listingsObject: ListingsObject = {}

    listings.forEach((listing) => {
      listingsObject[listing.id] = listing
    })

    return listingsObject
  } catch (error: any) {
    throw new Error(error)
  }
}
