import SbChannel from '@sendbird/uikit-react/Channel'

import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import RootLayout from '../../layout'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { IntlShape, useIntl } from 'react-intl'
import MobileNav from '../../../components/components/navbar/MobileNav'
import * as React from 'react'

export default function Channel() {
  const { channelUrl } = useParams()
  const [searchParams] = useSearchParams()
  const messageId = searchParams.get('messageId')
  const createdAt = searchParams.get('createdAt')
  const numCreatedAt = Number(createdAt)
  const navigate = useNavigate()
  const intl: IntlShape = useIntl()
  return (
    <RootLayout withCategories={false}>
      <div className="h-[calc(100vh-200px)] grid grid-cols-1">
        <SbChannel
          channelUrl={channelUrl as string}
          onBackClick={() => {
            navigate(localizedRouteGenerator(intl, 'route.messenger'))
          }}
          onSearchClick={() => {
            console.log(
              localizedRouteGenerator(intl, 'route.channelSearch', channelUrl),
            )
            navigate(
              localizedRouteGenerator(intl, 'route.channelSearch', channelUrl),
            )
          }}
          {...(messageId &&
            typeof numCreatedAt === 'number' && {
              startingPoint: numCreatedAt,
              highlightedMessageId: messageId,
              animatedMessageId: messageId,
            })}
          showSearchIcon
          onChatHeaderActionClick={() => {
            console.log(
              localizedRouteGenerator(
                intl,
                'route.channelSettings',
                channelUrl,
              ),
            )
            navigate(
              localizedRouteGenerator(
                intl,
                'route.channelSettings',
                channelUrl,
              ),
            )
          }}
        />
        <Outlet />
      </div>
      <MobileNav />
    </RootLayout>
  )
}
