import NavBar from '../components/components/navbar/NavBar'
import SearchModal from '../components/components/modals/SearchModal'
import RentModal from '../components/components/modals/RentModal'

import ToasterProvider from '../components/providers/ToasterProvider'

import './globals.css'
import ClientOnly from '../components/components/ClientOnly'
import { useAuth0 } from '@auth0/auth0-react'
import CategoryBoxModal from '../components/components/modals/CategoryBoxModal'

export const metadata = {
  title: 'Airbnb',
  description: 'Airbnb Clone',
}

export default function RootLayout({
  children,
  withCategories,
}: {
  children: React.ReactNode
  withCategories?: boolean
}) {
  const { user } = useAuth0()
  if (withCategories === undefined) {
    withCategories = true
  }

  return (
    // <html lang="en">
    //   <body className={font.className}>
    <>
      <ClientOnly>
        <ToasterProvider />
        <CategoryBoxModal />
        <SearchModal />
        <RentModal />
        <NavBar currentUser={user} withCategories={withCategories} />
      </ClientOnly>
      <div className="pb-20 pt-28">{children}</div>
    </>
    // </body>
    // </html>
  )
}
