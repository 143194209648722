import Avatar from '../Avatar'
import { User } from '@auth0/auth0-spa-js'
import { Listing } from '../../../types'
import Map from '../Map'
import * as React from 'react'
import { MdOutlineMessage } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { useIntl } from 'react-intl'
import ReactGA from 'react-ga4'
interface ListingInfoProps {
  listingId: string
  listing: Listing
  user: User
}

const ListingInfo: React.FC<ListingInfoProps> = ({
  listing,
  listingId,
  user,
}) => {
  // const { getByValue } = useCountries()

  const intl = useIntl()
  const navigate = useNavigate()
  // const coordinates = getByValue(locationValue)?.latlng

  const sharePage = (listingId) => {
    if (navigator.share) {
      navigator
        .share({
          title: 'WebShare API Demo',
          url: 'https://kotijahti.com/listings/' + listingId,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch(console.error)
    } else {
      console.log('This browser does not support sharing')
    }
  }

  return (
    <>
      <div>
        <div className="flex items-center space-x-3">
          <button
            className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 pl-4 pr-8 border border-black hover:border-transparent rounded"
            onClick={() => {
              ReactGA.event({
                category: 'interaction',
                action: 'favourite',
                nonInteraction: false,
              })
            }}
          >
            <img src="/heart.png" className="object-scale-down h-3 w-3 mr-1" />
            Favourite
          </button>
          <button
            onClick={() => {
              ReactGA.event({
                category: 'interaction',
                action: 'share',
                nonInteraction: false,
              })
              sharePage(listingId)
            }}
            className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 pl-4 pr-8 border border-black hover:border-transparent rounded"
          >
            <img src="/share.png" className="object-scale-down h-3 w-3 mr-1" />
            Share
          </button>

          <button
            className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 pl-4 pr-8 border border-black hover:border-transparent rounded"
            onClick={async () => {
              ReactGA.event({
                category: 'interaction',
                action: 'contact',
                nonInteraction: false,
              })
              // TODO: Generate non-colliding channel URLs
              const requestBody = {
                name:
                  listing?.location +
                  ' - ' +
                  listing?.monthly_price +
                  ' - ' +
                  user?.name +
                  ' (' +
                  user?.email +
                  ')',
                channel_url: 'kotijahti_chat_' + listing.id + '_',
                cover_url: 'https://sendbird.com/main/img/cover/cover_08.jpg',
                custom_type: 'contact_request',
                // is_distinct: true,
                user_ids: [
                  /*listing.creator.email*/ listing?.user_email,
                  user?.email,
                ],
              }
              // TODO:: Figure out why it doesn't accept this API token to create the contact request group channel
              const apiResponse = await fetch(
                `https://api-${'0FAA6CEC-2F88-403F-951A-78EB6CCD85E8'}.sendbird.com/v3/group_channels`,
                {
                  method: 'POST',
                  body: JSON.stringify(requestBody),
                  headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': 'c417b6f7b9a9bd15d229e4b498ea376458f17ca9',
                  },
                },
              )
              const createdChannel = await apiResponse.json()
              console.log(createdChannel)
              navigate(
                localizedRouteGenerator(
                  intl,
                  'route.channels',
                  createdChannel.channel_url,
                ),
              )
            }}
          >
            <MdOutlineMessage className="object-scale-down h-4 w-4 mr-1 ml-2" />{' '}
            Contact
          </button>
        </div>
      </div>
      <div className="col-span-4 flex flex-col gap-8 mt-16">
        <div className="flex flex-col gap-2">
          <div
            className="
            font-semibold
            flex
            flex-row
            items-center
            gap-2
          "
          >
            {/* TODO:: Change to be the user info of the listing creator */}
            <div>{listing.user_name}</div>
            <div>{listing.user_email}</div>
            {/*<Avatar src={listing.user_picture} />*/}
            {/*<ProfileOverview />*/}
          </div>
          <div className="flex items-center space-x-3 mt-8">
            <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
              <h1 className="mt-1 text-lg font-semibold sm:text-slate-900 md:text-2xl">
                The Basics
              </h1>
              <p className="text-sm leading-4 font-medium sm:text-slate-500 dark:sm:text-slate-400">
                What you want to know
              </p>
            </div>
            <div className="mt-4 text-xs font-light grid grid-cols-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/blueprint.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>{listing?.number_of_rooms ?? null}</span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/euro-bill.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>
                  {listing?.monthly_price ?? null}
                  <span className="text-slate-400 font-normal"></span>
                </span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/area.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>
                  {listing?.living_area ?? null}
                  <span className="text-slate-400 font-normal"></span>
                </span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/location.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span> {listing?.location ?? null}</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex items-center space-x-3 mt-8">
            <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
              <h1 className="mt-1 text-lg font-semibold sm:text-slate-900 md:text-2xl">
                The Features
              </h1>
              <p className="text-sm leading-4 font-medium sm:text-slate-500 dark:sm:text-slate-400">
                Extras that make this home special
              </p>
            </div>
            <div className="mt-4 text-xs font-light grid grid-cols-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/blueprint.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>{listing?.features ?? null}</span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/euro-bill.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>
                  {listing?.housing_features ?? null}
                  <span className="text-slate-400 font-normal"></span>
                </span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/area.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>
                  {listing?.pets_allowed ?? null}
                  <span className="text-slate-400 font-normal"></span>
                </span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/location.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span> {listing?.smoking_allowed ?? null}</span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/area.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span>
                  {listing?.parking ?? null}
                  <span className="text-slate-400 font-normal"></span>
                </span>
              </div>
              <div className="text-blue-600 flex items-center dark:text-blue-400">
                <img
                  src="/icons/location.png"
                  className="object-scale-down h-3 w-3 mr-1"
                />
                <span> {listing?.own_sauna ?? null}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <hr />
        <div className="h-64">
          <Map
            coordinates={[[listing.longitude, listing.latitude]]}
            center={[listing.longitude, listing.latitude]}
          />
        </div>
      </div>
    </>
  )
}

export default ListingInfo
