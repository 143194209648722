import SbMessageSearch from '@sendbird/uikit-react/MessageSearch'
import { useParams, useNavigate } from 'react-router-dom'

import { useNavigateOnBan } from '../hooks/useNavigateOnBan'
import MobileNav from '../../../components/components/navbar/MobileNav'
import * as React from 'react'

export default function MessageSearch() {
  const { channelUrl } = useParams()
  const navigator = useNavigate()
  useNavigateOnBan()
  return (
    <div className="h-[calc(100vh-200px)]">
      <SbMessageSearch
        channelUrl={channelUrl as string}
        onCloseClick={() => {
          navigator(`/channels/${channelUrl}`)
        }}
        onResultClick={(message) => {
          navigator(
            `/channels/${channelUrl}?messageId=${message?.messageId}&createdAt=${message?.createdAt}`,
          )
        }}
      />
      <MobileNav />
    </div>
  )
}
