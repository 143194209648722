import { IconType } from 'react-icons'
import { useSearchParams } from 'react-router-dom'
import useSearchModal from '../hooks/useSearchModal'

interface CategoryBoxProps {
  icon: IconType
  label: string
  selected?: boolean
}

const CategoryBox: React.FC<CategoryBoxProps> = ({
  icon: Icon,
  label,
  selected,
}) => {
  const searchModal = useSearchModal()
  const [searchParams, setSearchParams] = useSearchParams()
  // single-time read
  const params = Object.fromEntries(searchParams)
  const value = params[label] ? params[label] : ''

  function handleClick(event) {
    event.preventDefault()
    // The serialize function here would be responsible for
    // creating an object of { key: value } pairs from the
    // fields in the form that make up the query.
    const updatedQuery: any = {
      ...params,
      [label]: label === 'Rooms' && value === '' ? '1' : value,
    }
    setSearchParams(updatedQuery)
    searchModal.onOpen()
  }

  return (
    <div
      onClick={handleClick}
      className={`
        flex 
        flex-col 
        items-center 
        justify-center 
        gap-2
        p-3
        border-b-2
        hover:text-neutral-800
        transition
        cursor-pointer
        ${selected ? 'border-b-neutral-800' : 'border-transparent'}
        ${selected ? 'text-neutral-800' : 'text-neutral-500'}
      `}
    >
      <Icon size={26} />
      <div className="font-medium text-sm">{label}</div>
      <div className="small text-sm">
        {value === 'true' ? 'Yes' : value === 'false' ? 'No' : value}
      </div>
    </div>
  )
}

export default CategoryBox
