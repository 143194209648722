import React from 'react'
import { useLocation, Location } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import en from './languages/compiled/en.json'
import fi from './languages/compiled/fi.json'
import sv from './languages/compiled/sv.json'
import RoutesCenter from './routes/RoutesCenter'
import { useAuth0 } from '@auth0/auth0-react'
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider'

function loadLocaleData(locale: string): any {
  switch (locale) {
    case 'fi':
      return fi
    case 'sv':
      return sv
    default:
      return en
  }
}

function App() {
  const location: Location = useLocation()
  let lang: string = location.pathname.substring(1, 3)
  lang = lang === 'fi' ? lang : lang === 'sv' ? lang : 'en'
  const messages: any = loadLocaleData(lang)
  const { user } = useAuth0()

  return (
    <IntlProvider messages={messages} locale={lang} defaultLocale="en">
      <div className="App">
        <SendbirdProvider
          appId={'0FAA6CEC-2F88-403F-951A-78EB6CCD85E8'} // Specify your Sendbird application ID.
          userId={user?.email} // Specify your user ID.
          nickname={user?.name} // Specify your user nickname.
          showSearchIcon={true}
        >
          <RoutesCenter />
        </SendbirdProvider>
      </div>
    </IntlProvider>
  )
}

export default App
