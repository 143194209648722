export const extractPriceRangeFromParams = (
  searchParams: URLSearchParams,
): [number, number] => {
  const priceRange = searchParams.get('Price Range')
  if (!priceRange) {
    return [0, 3000]
  }

  const [min, max] = priceRange.split('-')
  return [parseInt(min), parseInt(max)]
}

export const extractLivingAreaRangeFromParams = (
  searchParams: URLSearchParams,
): [number, number] => {
  const livingAreaRange = searchParams.get('Living Area')
  if (!livingAreaRange) {
    return [0, 200]
  }

  const [min, max] = livingAreaRange.split('-')
  return [parseInt(min), parseInt(max)]
}
