import SbMessageSearch from '@sendbird/uikit-react/MessageSearch'
import { useNavigate, useParams } from 'react-router-dom'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { IntlShape, useIntl } from 'react-intl'
import MobileNav from '../../../components/components/navbar/MobileNav'
import * as React from 'react'

export default function MessageSearch() {
  const { channelUrl } = useParams()
  const navigate = useNavigate()
  const intl: IntlShape = useIntl()
  return (
    <div className="h-[calc(100vh-200px)]">
      <SbMessageSearch
        channelUrl={channelUrl as string}
        onCloseClick={() => {
          navigate(localizedRouteGenerator(intl, 'route.channels', channelUrl))
        }}
        onResultClick={(message) => {
          navigate(
            localizedRouteGenerator(
              intl,
              'route.channels',
              channelUrl,
              `messageId=${message?.messageId}&createdAt=${message?.createdAt}`,
            ),
          )
        }}
      />
      <MobileNav />
    </div>
  )
}
