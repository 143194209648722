import * as React from 'react'
import { useParams } from 'react-router-dom'
import RootLayout from './layout'
import ListingPage from './listings/[listingId]/page'
import MobileNav from '../components/components/navbar/MobileNav'

export default function Listing() {
  const params = useParams()

  return (
    <>
      <RootLayout>
        <ListingPage params={params} />
      </RootLayout>
      <MobileNav />
    </>
    // <div className="h-screen">
    //   <Header intl={intl} />
    //   <main>
    //     <div className="container mx-auto rounded overflow-hidden shadow-lg px-6 py-4 mb-8 mt-4 items-center ">
    //       <div className="grid md:grid:cols-1 grid-cols-5">
    //         <div className="col-span-3">
    //           <Carousel
    //             autoFocus={true}
    //             showArrows={true}
    //             thumbWidth={120}
    //             emulateTouch
    //             infiniteLoop
    //             useKeyboardArrows={true}
    //             dynamicHeight={true}
    //             showThumbs={true}
    //             showIndicators={false}
    //           >
    //             {listing?.images.map((image) => (
    //               <div key={image}>
    //                 <img src={`/images/${listingId}/${image}`} />
    //                 {listing?.captions ? (
    //                   <p className="legend">Caption</p>
    //                 ) : null}
    //               </div>
    //             )) ?? null}
    //           </Carousel>
    //         </div>
    //         <div className="col-span-2">Contact</div>
    //       </div>
    //       <div className="flex items-center space-x-3">
    //         <button className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">
    //           <img
    //             src="/heart.png"
    //             className="object-scale-down h-3 w-3 mr-1"
    //           />
    //           Favourite
    //         </button>
    //         <button
    //           onClick={() => sharePage(listingId)}
    //           className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
    //         >
    //           <img
    //             src="/share.png"
    //             className="object-scale-down h-3 w-3 mr-1"
    //           />
    //           Share
    //         </button>
    //       </div>
    //       <div className="flex items-center space-x-3 mt-8">
    //         <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
    //           <h1 className="mt-1 text-lg font-semibold sm:text-slate-900 md:text-2xl">
    //             The Basics
    //           </h1>
    //           <p className="text-sm leading-4 font-medium sm:text-slate-500 dark:sm:text-slate-400">
    //             What you want to know
    //           </p>
    //         </div>
    //         <div className="mt-4 text-xs font-light grid grid-cols-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
    //           <div className="text-blue-600 flex items-center dark:text-blue-400">
    //             <img
    //               src="/blueprint.png"
    //               className="object-scale-down h-3 w-3 mr-1"
    //             />
    //             <span>{listing?.number_of_rooms ?? null}</span>
    //           </div>
    //           <div className="text-blue-600 flex items-center dark:text-blue-400">
    //             <img
    //               src="/euro-bill.png"
    //               className="object-scale-down h-3 w-3 mr-1"
    //             />
    //             <span>
    //               {listing?.rent ?? null}
    //               <span className="text-slate-400 font-normal"></span>
    //             </span>
    //           </div>
    //           <div className="text-blue-600 flex items-center dark:text-blue-400">
    //             <img
    //               src="/area.png"
    //               className="object-scale-down h-3 w-3 mr-1"
    //             />
    //             <span>
    //               {listing?.living_area ?? null}
    //               <span className="text-slate-400 font-normal"></span>
    //             </span>
    //           </div>
    //           <div className="text-blue-600 flex items-center dark:text-blue-400">
    //             <img
    //               src="/location.png"
    //               className="object-scale-down h-3 w-3 mr-1"
    //             />
    //             <span> {listing?.location ?? null}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </main>
    //   <Footer />
    // </div>
  )
}
