import SbChannelSettings from '@sendbird/uikit-react/ChannelSettings'
import { useNavigate, useParams } from 'react-router-dom'
import RootLayout from '../../layout'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { IntlShape, useIntl } from 'react-intl'
import MobileNav from '../../../components/components/navbar/MobileNav'
import * as React from 'react'

export default function ChannelSettings() {
  const { channelUrl } = useParams()
  const navigate = useNavigate()
  const intl: IntlShape = useIntl()
  return (
    <RootLayout withCategories={false}>
      <div className="h-[calc(100vh-200px)]">
        <SbChannelSettings
          channelUrl={channelUrl as string}
          onCloseClick={() => {
            navigate(
              localizedRouteGenerator(intl, 'route.channels', channelUrl),
            )
          }}
          onLeaveChannel={() => {
            navigate(localizedRouteGenerator(intl, 'route.messenger'))
          }}
        />
      </div>
      <MobileNav />
    </RootLayout>
  )
}
