import { useMemo } from 'react'
import { BiSearch } from 'react-icons/bi'

import useSearchModal from '../../hooks/useSearchModal'
import { useSearchParams } from 'react-router-dom'
import { SearchCodes } from '../../../types'
import { capitalize } from '../../../functions/string-manipulation'

const Search = () => {
  const searchModal = useSearchModal()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCodesJSON = searchParams?.get('searchCodes')

  const locationLabel = useMemo(() => {
    // Returns the search code location names for the search bar, otherwise helsinki if none set
    if (searchCodesJSON) {
      const searchCodes: SearchCodes = JSON.parse(searchCodesJSON)
      return searchCodes.features
        .map((feature) => capitalize(feature.properties.name))
        .join(', ')
    }

    return 'Helsinki'
  }, [searchCodesJSON])

  return (
    <div
      onClick={searchModal.onOpen}
      className="
        border-[1px] 
        w-full 
        md:w-auto 
        py-2 
        rounded-full 
        shadow-sm 
        hover:shadow-md 
        transition 
        cursor-pointer
      "
    >
      <div
        className="
          flex 
          flex-row 
          items-center 
          justify-between
        "
      >
        <div
          className="
            text-sm 
            font-semibold 
            px-6
          "
        >
          {locationLabel}
        </div>
        <div
          className="
            hidden 
            sm:block 
            text-sm 
            font-semibold 
            px-6 
            border-x-[1px] 
            flex-1 
            text-center
          "
        ></div>
        <div
          className="
            text-sm 
            pl-6 
            pr-2 
            text-gray-600 
            flex 
            flex-row 
            items-center 
            gap-3
          "
        >
          <div
            className="
              p-2 
              bg-blue-500
              rounded-full 
              text-white
            "
          >
            <BiSearch size={18} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
