import React, { useRef, useEffect, useState } from 'react'
import mapboxgl, { Coordinate } from 'mapbox-gl'
import './Map.css'
import data from '../../data/geoLocations.json'
import polylabel from 'polylabel'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ListingsObject } from '../../types'
import { useSearchParams } from 'react-router-dom'

const districtCenters: any = {
  type: 'FeatureCollection',
  features: [],
}

const options = [
  {
    name: 'Rent Per Square Meter',
    description: 'Cost of renting a one bedroom apartment per square meter',
    property: 'rent_per_sq_m',
    stops: [
      [21, '#caf0f8'],
      [22, '#ade8f4'],
      [23, '#90e0ef'],
      [24, '#48cae4'],
      [25, '#00b4d8'],
      [26, '#0096c7'],
      [27, '#0077b6'],
      [28, '#023e8a'],
      [29, '#03045e'],
    ],
  },
]

data.features.forEach((feature) => {
  const centerPoint = polylabel(feature.geometry.coordinates)
  districtCenters.features.push({
    type: 'Feature',
    properties: {
      title:
        feature.properties.name.charAt(0).toUpperCase() +
        feature.properties.name.slice(1),
      rent_per_sq_m: feature.properties.rent_per_sq_m,
    },
    geometry: {
      type: 'Point',
      coordinates: [centerPoint[0], centerPoint[1]],
    },
  })
})

mapboxgl.accessToken =
  'pk.eyJ1Ijoic3BpbmFhZGJ1c2luZXNzIiwiYSI6ImNrYXdvOGw4OTA2YnoydG12OWwwajZjZ2QifQ.iFZtIDeXP459qdiXk3wmVQ'

interface MapProps {
  displayListings?: ListingsObject
  coordinates?: Array<[number, number]>
  center?: [number, number]
}

const Map = ({ displayListings, coordinates, center }: MapProps) => {
  const listingLocations: any = {
    type: 'FeatureCollection',
    features: [],
  }
  if (displayListings !== undefined) {
    Object.keys(displayListings).forEach((listingId) => {
      listingLocations.features.push({
        type: 'Feature',
        properties: {
          id: listingId,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            displayListings[listingId].longitude,
            displayListings[listingId].latitude,
          ],
        },
      })
    })
  }
  if (coordinates !== undefined) {
    coordinates.forEach(([longitude, latitude]) => {
      listingLocations.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      })
    })
  }

  const mapContainer = useRef(null)
  const map = useRef(null)
  const activeRef = useRef(options[0])
  const [active, setActive] = useState(options[0])
  const [lng, setLng] = useState(center ? center[0] : 24.9384)
  const [lat, setLat] = useState(center ? center[1] : 60.1699)
  const [zoom, setZoom] = useState(center ? 13 : 11)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    // After the map is already initialized, add the listing locations
    if (map.current && displayListings !== undefined) {
      Object.keys(displayListings).forEach((key) => {
        // create the popup
        const popupListing = displayListings[key]
        console.log('popupListing', popupListing)
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
          '<a href=/en/listing/' +
            key +
            '><p><img alt="popup listing image" src=https://kotijahti-imagebucket97210811-mnowgoqvcwya.s3.eu-central-1.amazonaws.com/' +
            popupListing.images[0] +
            '></img></p>' +
            '<b>' +
            popupListing.location +
            '</b>' +
            '<br>' +
            popupListing.monthly_price +
            '€/month' +
            '</a>',
        )
        new mapboxgl.Marker({ color: 'blue' })
          .setLngLat([popupListing.longitude, popupListing.latitude])
          .setPopup(popup)
          .addTo(map.current)
      })
    }
    if (map.current && coordinates !== undefined) {
      coordinates.forEach(([longitude, latitude]) => {
        new mapboxgl.Marker({ color: 'blue' })
          .setLngLat([longitude, latitude])
          .addTo(map.current)
      })
    }
  }, [JSON.stringify(displayListings), JSON.stringify(coordinates)])

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
      maxBounds: [
        24.554930459262675, 60.05562544965014,
        /*south-west coordinates*/ 25.240608729433433, 60.44263813033785,
      ], // Northeast coordinates;
    })

    // map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
    // TODO:: Add ability to disable map movement for mobile non-search usage
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4))
      setLat(map.current.getCenter().lat.toFixed(4))
      setZoom(map.current.getZoom().toFixed(2))
    })

    map.current.on('load', () => {
      map.current.addSource('region', {
        type: 'geojson',
        // @ts-ignore
        data,
      })
      map.current.addSource('centers', {
        type: 'geojson',
        // @ts-ignore
        data: districtCenters,
      })
      // map.current.addSource('listings', {
      //   type: 'geojson',
      //   // @ts-ignore
      //   data: listingLocations,
      // })

      map.current.setLayoutProperty('country-label', 'text-field', [
        'format',
        ['get', 'name_en'],
        { 'font-scale': 1.2 },
        '\n',
        {},
        ['get', 'name'],
        {
          'font-scale': 0.8,
          'text-font': [
            'literal',
            ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
          ],
        },
      ])

      map.current.addLayer({
        id: 'region-fills',
        type: 'fill',
        source: 'region',
        paint: {
          'fill-opacity': 0.3,
          'fill-opacity-transition': { duration: 2000 },
        },
      })

      map.current.setPaintProperty('region-fills', 'fill-color', {
        property: active.property,
        stops: active.stops,
      })

      // Add country borders
      map.current.addLayer({
        id: 'region-borders',
        type: 'line',
        source: 'region',
        layout: {},
        paint: {
          'line-color': '#627BC1',
          'line-width': 1,
        },
      })

      // Add country hover layer
      map.current.addLayer({
        id: 'region-fills-hover',
        type: 'fill',
        source: 'region',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.1,
          'fill-opacity-transition': { duration: 2000 },
        },
        filter: ['==', 'name', ''],
      })

      // Add a symbol layer for titles
      map.current.addLayer({
        id: 'centers',
        type: 'symbol',
        source: 'centers',
        layout: {
          // get the title name from the source's "title" property
          'text-field': ['get', 'title'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        },
        paint: {
          'text-color': '#ffffff',
        },
      })

      // const image = new Image(35, 35);
      // image.src = Cat;
      // map.current.addImage('cat', image);
      // console.log('map has image', map.current.hasImage('cat'))

      // Add a symbol layer listing icons
      // map.current.addLayer({
      //   id: 'listings',
      //   type: 'symbol',
      //   source: 'listings',
      //   layout: {
      //     'icon-image': 'cat', // reference the image
      //     'icon-size': 1
      //   }
      // })

      // Add country hover effect
      map.current.on('mousemove', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['region-fills'],
        })

        if (features.length) {
          map.current.getCanvas().style.cursor = 'pointer'
          map.current.setFilter('region-fills-hover', [
            '==',
            'name',
            features[0].properties.name,
          ])
        } else {
          map.current.setFilter('region-fills-hover', ['==', 'name', ''])
          map.current.getCanvas().style.cursor = ''
        }
      })

      // Add country un-hover effect
      map.current.on('mouseout', () => {
        map.current.getCanvas().style.cursor = 'auto'
        map.current.setFilter('region-fills-hover', ['==', 'name', ''])
      })

      // Add country onclick effect
      // map.current.on('click', (e) => {
      //   const features = map.current.queryRenderedFeatures(e.point, {
      //     layers: ['region-fills'],
      //   })
      //   if (!features.length) return
      //   const { properties } = features[0]
      //   const { property, description } = activeRef.current
      //   alert(`(${properties.name}) ${properties[property]} ${description}`)
      // })
      //
      // setMap(map)

      //       map.current.loadImage(
      //           'https://static-00.iconduck.com/assets.00/mapbox-icon-512x512-9rj6ikyk.png',
      //           (error, image) => {
      //             if (error) throw error;
      //
      // // Add the image to the map style.
      //             map.current.addImage('cat', image);
      //
      //
      // // Add a layer to use the image to represent the data.
      //             map.current.addLayer({
      //               'id': 'listings',
      //               'type': 'symbol',
      //               'source': 'listings', // reference the data source
      //               'layout': {
      //                 'icon-image': 'cat', // reference the image
      //                 'icon-size': 0.05
      //               }
      //             });
      //           }
      //       );
    })

    // Clean up on unmount
    // return () => map.current.remove()
    // TODO:: Make useeffect function run when displayListings changes
  }, [active.property, active.stops, lat, lng, zoom, searchParams])

  // useEffect(() => {
  //   paint()
  // }, [active])
  //
  // const paint = () => {
  //   if (map.current) {
  //     map.current.setPaintProperty('region-fills', 'fill-color', {
  //       property: active.property,
  //       stops: active.stops,
  //     })
  //     activeRef.current = active
  //   }
  // }
  //
  // const changeState = (i) => {
  //   setActive(options[i])
  //   map.current.setPaintProperty('region-fills', 'fill-color', {
  //     property: active.property,
  //     stops: active.stops,
  //   })
  // }

  return <div ref={mapContainer} className="map-container h-full w-full" />
}

export default Map
