import React, { useCallback, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import useRentModal from '../../hooks/useRentModal'
import MenuItem from './MenuItem'
import Avatar from '../Avatar'
import { User } from '@auth0/auth0-spa-js'
import { useAuth0 } from '@auth0/auth0-react'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { useIntl } from 'react-intl'
import ReactGA from 'react-ga4'

interface UserMenuProps {
  currentUser?: User | null
}

const UserMenu: React.FC<UserMenuProps> = ({ currentUser }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { loginWithRedirect, logout } = useAuth0()

  const handleLogin = async () => {
    ReactGA.event({
      category: 'navigation',
      action: 'login',
      nonInteraction: false,
    })
    await loginWithRedirect({
      appState: {
        returnTo: '/profile',
      },
    })
  }

  const handleLogout = () => {
    ReactGA.event({
      category: 'navigation',
      action: 'logout',
      nonInteraction: false,
    })
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const rentModal = useRentModal()

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(() => {
    ReactGA.event({
      category: 'navigation',
      action: 'sidebar',
      nonInteraction: false,
    })
    setIsOpen((value) => !value)
  }, [])

  const onRent = useCallback(() => {
    if (!currentUser) {
      return handleLogin()
    }
    rentModal.onOpen()
  }, [rentModal, currentUser])

  return (
    <div className="relative">
      <div className="flex flex-row items-center gap-3">
        <div
          onClick={onRent}
          className="
            hidden
            md:block
            text-sm 
            font-semibold 
            py-3 
            px-4 
            rounded-full 
            hover:bg-neutral-100 
            transition 
            cursor-pointer
          "
        >
          Create your own listing!
        </div>
        <div
          onClick={toggleOpen}
          className="
          p-4
          md:py-1
          md:px-2
          border-[1px] 
          border-neutral-200 
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
          <AiOutlineMenu />
          <div className="hidden md:block">
            <Avatar src={currentUser?.picture} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="
            absolute 
            rounded-xl 
            shadow-md
            w-[40vw]
            md:w-3/4 
            bg-white 
            overflow-hidden 
            right-0 
            top-12 
            text-sm
          "
        >
          <div className="flex flex-col cursor-pointer">
            <div
              className="
                      flex
                        px-4
                        py-3
                        transition
                        font-semibold
                        justify-between"
            >
              <Link to="/fi">
                <span className="hover:bg-neutral-100">{'FI'}</span>
              </Link>
              <Link to="/sv">
                <span className="hover:bg-neutral-100">{'SV'}</span>
              </Link>
              <Link to="/en">
                <span className="hover:bg-neutral-100">{'EN'}</span>
              </Link>
            </div>
            <hr />
            {currentUser ? (
              <>
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.profile',
                    description:
                      'Link to the profile page from the user menu in the header',
                    defaultMessage: 'My Profile',
                  })}
                  onClick={() =>
                    navigate(
                      localizedRouteGenerator(intl, 'route.profileOverview'),
                    )
                  }
                />
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.messages',
                    description:
                      'Link to the messages page from the user menu in the header',
                    defaultMessage: 'My Messages',
                  })}
                  onClick={() =>
                    navigate(localizedRouteGenerator(intl, 'route.messenger'))
                  }
                />
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.favourites',
                    description:
                      'Link to the favourites page from the user menu in the header',
                    defaultMessage: 'My favourites',
                  })}
                  onClick={() =>
                    localizedRouteGenerator(intl, 'route.favourites')
                  }
                />
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.userListings',
                    description:
                      "Link to the user's own listings page from the user menu in the header",
                    defaultMessage: 'My listings',
                  })}
                  onClick={() =>
                    navigate(localizedRouteGenerator(intl, 'route.properties'))
                  }
                />
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.createListing',
                    description:
                      'Link to the create listing modal from the user menu in the header',
                    defaultMessage: 'Create listing',
                  })}
                  onClick={rentModal.onOpen}
                />
                <hr />
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.logout',
                    description:
                      'Triggers logout from the user menu in the header',
                    defaultMessage: 'Logout',
                  })}
                  onClick={() => handleLogout()}
                />
              </>
            ) : (
              <>
                <MenuItem
                  label={intl.formatMessage({
                    id: 'usermenu.login',
                    description:
                      'Triggers login from the user menu in the header',
                    defaultMessage: 'Login',
                  })}
                  onClick={handleLogin}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default UserMenu
