import * as React from 'react'
import { Navigate, useLocation, useMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { IntlShape } from 'react-intl'

export default function NoLang(props) {
  const children = props.children
  const location = useLocation()
  const intl: IntlShape = useIntl()

  return <Navigate to={`/${intl.locale}`} />
}
