import ClientOnly from '../../../components/components/ClientOnly'
import EmptyState from '../../../components/components/EmptyState'

import ListingClient from './ListingClient'
import { useAuth0 } from '@auth0/auth0-react'
import getListings from '../../../components/actions/getListings'
import { useEffect, useState } from 'react'
import { ListingsObject } from '../../../types'
import Loader from '../../../components/components/Loader'

interface IParams {
  listingId?: string
}

const ListingPage = ({ params }: { params: IParams }) => {
  const { user } = useAuth0()
  const [dbListings, setDbListings] = useState<ListingsObject>({})
  const [isLoading, setIsLoading] = useState(true)
  const fetchListings = async () => {
    const fetchedListings = await getListings()
    setDbListings(fetchedListings)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchListings()
  }, [])

  const listing =
    Object.keys(dbListings).length > 0 && dbListings[params.listingId]
      ? dbListings[params.listingId]
      : null
  const reservations = []

  if (isLoading) {
    return (
      <ClientOnly>
        <Loader />
      </ClientOnly>
    )
  }

  if (!listing) {
    return (
      <ClientOnly>
        <EmptyState />
      </ClientOnly>
    )
  }

  return (
    <ClientOnly>
      <ListingClient
        listingId={params.listingId}
        listing={listing}
        reservations={reservations}
        currentUser={user}
      />
    </ClientOnly>
  )
}

export default ListingPage
