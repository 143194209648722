import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate()

  const domain =
    process.env.NODE_ENV === 'production'
      ? 'dev-j5nmx6rxqvwz5baz.eu.auth0.com'
      : 'dev-j5nmx6rxqvwz5baz.eu.auth0.com'
  const clientId =
    process.env.NODE_ENV === 'production'
      ? 'gpK5GyfykG3Ux5b1VSYkOytCyy2PRs38'
      : 'XboXz0wQTi9Otm7jui9TOm0D0YIAhL3I'
  const redirectUri =
    process.env.NODE_ENV === 'production'
      ? 'https://kotijahti.com/en/callback'
      : 'http://localhost:3000/en/callback'

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId && redirectUri)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
