import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Map from '../components/components/Map'
import { ListingsObject, SearchCodes } from '../types'
import whichPolygon from 'which-polygon'
import useRentModal from '../components/hooks/useRentModal'
import RootLayout from './layout'
import RootListingsLayout from './page'
import {
  extractLivingAreaRangeFromParams,
  extractPriceRangeFromParams,
} from '../functions/url-search-params'
import getListings from '../components/actions/getListings'
import MobileNav from '../components/components/navbar/MobileNav'
import Loader from '../components/components/Loader'
import ClientOnly from '../components/components/ClientOnly'
const extractFeatures = (features: string) => {
  if (!features) {
    return []
  }

  const featuresArray = features.split(', ')
  return featuresArray
}

const filterListingsByParams = (
  listings: ListingsObject,
  searchParams: URLSearchParams,
  searchCodes: SearchCodes,
): ListingsObject => {
  let filteredListings: ListingsObject = {}
  if (!searchParams) {
    filteredListings = listings
  } else {
    const largeGeoQuery = whichPolygon(searchCodes)
    Object.entries(listings).map(([id, listing]) => {
      // Is it within the area of selected districts?
      if (
        searchCodes.features.length &&
        !largeGeoQuery([listing.longitude, listing.latitude], true)
      ) {
        return
      }
      // Does it match the rentalType
      if (
        searchParams.has('Type') &&
        listing.rental_type !== searchParams.get('Type')
      ) {
        return
      }
      const listingAreaInt = parseInt(listing.living_area, 10)
      const livingArea = extractLivingAreaRangeFromParams(searchParams)
      if (searchParams.has('Living Area') && listingAreaInt < livingArea[0]) {
        return
      }
      if (searchParams.has('Living Area') && listingAreaInt > livingArea[1]) {
        return
      }
      const priceRange = extractPriceRangeFromParams(searchParams)
      if (
        searchParams.has('Price Range') &&
        listing.monthly_price < priceRange[0]
      ) {
        return
      }
      const listingPriceInt = listing.monthly_price ? listing.monthly_price : 0
      if (searchParams.has('Price Range') && listingPriceInt > priceRange[1]) {
        return
      }
      const listingRoomCount = parseInt(listing.number_of_rooms, 10)
      if (
        searchParams.has('Rooms') &&
        listingRoomCount < parseInt(searchParams.get('Rooms'))
      ) {
        return
      }
      const listingFeatures = extractFeatures(listing.features)
      if (searchParams.get('Furnished') === 'true' && !listing.furnished) {
        return
      }
      if (searchParams.get('Balcony') === 'true' && !listing.balcony) {
        return
      }
      // TODO: Add filter for commmon sauna
      filteredListings[id] = listing
    })
  }
  return filteredListings
}

export default function Home() {
  const navigate = useNavigate()
  const [geoQuery, setGeoQuery] = useState('')
  const [geoData, setGeoData] = useState({})
  const [searchCodes, setSearchCodes] = useState({
    type: 'FeatureCollection',
    features: [],
  })
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const [renderMap, setRenderMap] = useState(!isMobile)
  const [timer, setTimer] = useState(null)
  const intl: IntlShape = useIntl()
  const { user } = useAuth0()
  const { loginWithRedirect } = useAuth0()
  const rentModal = useRentModal()
  const [dbListings, setDbListings] = useState<ListingsObject>({})
  const [isLoading, setIsLoading] = useState(true)

  const fetchListings = async () => {
    const fetchedListings = await getListings()
    setDbListings(fetchedListings)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchListings()
  }, [])

  // TODO:: Cache this so it only loads once unless a new listing is created

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/profile',
      },
    })
  }

  const onRent = useCallback(() => {
    if (!user) {
      return handleLogin()
    }

    rentModal.onOpen()
  }, [rentModal, user])

  const [searchParams, setSearchParams] = useSearchParams()
  const updateSearchCodesFromSearchParams = (searchParams: URLSearchParams) => {
    const searchCodesString = searchParams.get('searchCodes')
    if (searchCodesString) {
      setSearchCodes(JSON.parse(searchCodesString))
    } else {
      setSearchCodes({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }
  // updateSearchCodesFromSearchParams(searchParams)

  // TODO:: Convert searchParams from finnish or swedish to english
  //filter listings by searchParams from react-router-dom v6 when search params change
  let filteredListings: ListingsObject = filterListingsByParams(
    dbListings,
    searchParams,
    searchCodes,
  )
  useEffect(() => {
    updateSearchCodesFromSearchParams(searchParams)
    filteredListings = filterListingsByParams(
      dbListings,
      searchParams,
      searchCodes,
    )
  }, [searchParams])
  return (
    <>
      <RootLayout
        withCategories={!(isMobile && process.env.NODE_ENV === 'production')}
      >
        <div
          className={`
            grid
            grid-cols-1
            ${renderMap ? 'lg:grid-cols-2' : 'lg:grid-cols-1'}
            gap-8
          `}
        >
          {renderMap && (
            <div className="h-[calc(100vh-200px)]">
              <Map displayListings={filteredListings} />
            </div>
          )}
          {/* Only render 1 or the other when it's mobile */}
          {isMobile && renderMap ? null : (
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <RootListingsLayout
                  listings={filteredListings}
                  renderMap={renderMap}
                />
              )}
            </div>
          )}
        </div>
        <MobileNav />
      </RootLayout>

      {/*<main className="w-full">*/}
      {/*  <div className="my-20 flex items-center justify-center">*/}
      {/*    <div className="flex grow items-center mx-6 shadow rounded border-0 p-3 md: max-w-xl lg:max-w-6xl">*/}
      {/*      <div className="flex grow items-center py-4">*/}
      {/*        <div className="grow px-2 flex items-center">*/}
      {/*          <div className="flex flex-col">*/}
      {/*            /!*<Avatar />*!/*/}
      {/*            <div className="grow px-2 flex items-center">*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                className="w-6 h-6 mr-2 text-blue-600"*/}
      {/*                fill="none"*/}
      {/*                viewBox="0 0 24 24"*/}
      {/*                stroke="currentColor"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  strokeLinecap="round"*/}
      {/*                  strokeLinejoin="round"*/}
      {/*                  strokeWidth="2"*/}
      {/*                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*              <input*/}
      {/*                value={geoQuery}*/}
      {/*                onChange={(event) => searchValueChange(event)}*/}
      {/*                type="search"*/}
      {/*                name="name"*/}
      {/*                placeholder="Helsinki"*/}
      {/*                className="grow py-2 border-b-2 border-blue-400 outline-none focus:border-green-400"*/}
      {/*              />*/}
      {/*              <Link to={localizedRouteGenerator(intl, 'route.search')}>*/}
      {/*                <button*/}
      {/*                  disabled={!Object.keys(searchCodes).length}*/}
      {/*                  className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-500"*/}
      {/*                >*/}
      {/*                  <FormattedMessage*/}
      {/*                    id="home.search.button"*/}
      {/*                    description="The search button text"*/}
      {/*                    defaultMessage="Search"*/}
      {/*                  />*/}
      {/*                </button>*/}
      {/*              </Link>*/}
      {/*            </div>*/}
      {/*            <div*/}
      {/*              id="searchResults"*/}
      {/*              className="w-full max-w-sm bg-white rounded divide-y divide-gray-100 shadow"*/}
      {/*            >*/}
      {/*              <div className="w-full flex flex-row">*/}
      {/*                /!*{Object.values(searchCodes).map((item: any) => (*!/*/}
      {/*                /!*  <div*!/*/}
      {/*                /!*    key={item.postalcodearea}*!/*/}
      {/*                /!*    className="text-white text-lg font-bold p-2 py-1 bg-gray-500 rounded-md m-2 cursor-pointer"*!/*/}
      {/*                /!*    onClick={() => {*!/*/}
      {/*                /!*      // 👇️ create copy of state object*!/*/}
      {/*                /!*      const copy = {...searchCodes}*!/*/}
      {/*                /!*      // 👇️ remove salary key from object*!/*/}
      {/*                /!*      delete copy[item.postalcodearea]*!/*/}
      {/*                /!*      setSearchCodes(copy)*!/*/}
      {/*                /!*    }}*!/*/}
      {/*                /!*  >*!/*/}
      {/*                /!*    {searchCodes[item.postalcodearea].nameofthepostalcodearea_en.toString()}*!/*/}
      {/*                /!*  </div>*!/*/}
      {/*                /!*))}*!/*/}
      {/*                {Object.values(searchCodes).map((item: any) => (*/}
      {/*                  <div*/}
      {/*                    key={item.postalcodearea}*/}
      {/*                    onClick={() => {*/}
      {/*                      // 👇️ create copy of state object*/}
      {/*                      const copy = { ...searchCodes }*/}
      {/*                      // 👇️ remove salary key from object*/}
      {/*                      delete copy[item.postalcodearea]*/}
      {/*                      setSearchCodes(copy)*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    <a*/}
      {/*                      href="#"*/}
      {/*                      className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"*/}
      {/*                    >*/}
      {/*                      <h5 className="mb-2 text-l font-bold tracking-tight text-gray-900 dark:text-white">*/}
      {/*                        {searchCodes[*/}
      {/*                          item.postalcodearea*/}
      {/*                        ].nameofthepostalcodearea_en.toString()}*/}
      {/*                      </h5>*/}
      {/*                      /!*<p className="font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>*!/*/}
      {/*                    </a>*/}
      {/*                  </div>*/}
      {/*                ))}*/}
      {/*              </div>*/}
      {/*              {filteredGeoData.map((geoItem: any) => (*/}
      {/*                <div*/}
      {/*                  key={geoItem.postalcodearea}*/}
      {/*                  className="divide-y divide-gray-100 dark:divide-gray-700"*/}
      {/*                  onClick={() => {*/}
      {/*                    const newSearchCodes = searchCodes*/}
      {/*                    newSearchCodes[geoItem.postalcodearea] = geoItem*/}
      {/*                    setSearchCodes({ ...newSearchCodes })*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  <a*/}
      {/*                    href="#"*/}
      {/*                    className="flex py-3 px-4 hover:bg-gray-100"*/}
      {/*                  >*/}
      {/*                    <div className="flex-shrink-0">*/}
      {/*                      /!*<img className="w-11 h-11 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Jesse image"/>*!/*/}
      {/*                      /!*<div className="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-blue-600 rounded-full border border-white">*!/*/}
      {/*                      /!*  <svg className="w-3 h-3 text-white" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"*!/*/}
      {/*                      /!*       xmlns="http://www.w3.org/2000/svg">*!/*/}
      {/*                      /!*    <path*!/*/}
      {/*                      /!*      d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>*!/*/}
      {/*                      /!*    <path*!/*/}
      {/*                      /!*      d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>*!/*/}
      {/*                      /!*  </svg>*!/*/}
      {/*                      /!*</div>*!/*/}
      {/*                    </div>*/}
      {/*                    <div className="pl-3 w-full">*/}
      {/*                      <div className="text-gray-500 text-sm mb-1.5">*/}
      {/*                        <span className="font-semibold text-gray-900">*/}
      {/*                          {geoItem.nameofthepostalcodearea_en}*/}
      {/*                        </span>*/}
      {/*                        <br />*/}
      {/*                        {geoItem.nameofthemunicipality_en},{' '}*/}
      {/*                        {geoItem.nameofthesubregionalunit_en},{' '}*/}
      {/*                        {geoItem.nameoftheregion_en}*/}
      {/*                      </div>*/}
      {/*                      <div className="text-xs text-blue-600">*/}
      {/*                        {geoItem.nameofthemajorregion_en}*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                  </a>*/}
      {/*                </div>*/}
      {/*              ))}*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="px-2 flex items-center">*/}
      {/*          <button*/}
      {/*            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"*/}
      {/*            onClick={() => {*/}
      {/*              fetchGeoData(geoQuery)*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Post Free Listing*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="m-6 grid grid-cols-1 gap-4 md:grid-cols-3">*/}
      {/*    <Link to={localizedRouteGenerator(intl, 'route.about')}>*/}
      {/*      <div className="max-w-md mx-auto shadow rounded border-0 p-3">*/}
      {/*        <div className="flex items-center">*/}
      {/*          <img*/}
      {/*            src="/eco-house.png"*/}
      {/*            alt="TechEffect tmi"*/}
      {/*            className="object-scale-down h-8 w-8"*/}
      {/*          />*/}
      {/*          <p className="mx-3 font-bold">Learn &rarr;</p>*/}
      {/*        </div>*/}
      {/*        <p>What makes Kotijahti different</p>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*    <Link to="https://play.google.com/store/apps/details?id=com.travel_atlas.twa&hl=en&gl=US">*/}
      {/*      <div className="max-w-md mx-auto shadow rounded border-0 p-3">*/}
      {/*        <div className="flex items-center">*/}
      {/*          <img*/}
      {/*            src="/play.png"*/}
      {/*            alt="TechEffect tmi"*/}
      {/*            className="object-scale-down h-8 w-8"*/}
      {/*          />*/}
      {/*          <p className="mx-3 font-bold">Get our App for Free &rarr;</p>*/}
      {/*        </div>*/}
      {/*        <p>Available on the Google Play Store</p>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*    <Link to={localizedRouteGenerator(intl, 'route.business')}>*/}
      {/*      <div className="max-w-md mx-auto shadow rounded border-0 p-3">*/}
      {/*        <div className="flex items-center">*/}
      {/*          <img*/}
      {/*            src="/budget.png"*/}
      {/*            alt="TechEffect tmi"*/}
      {/*            className="object-scale-down h-8 w-8"*/}
      {/*          />*/}
      {/*          <p className="mx-3 font-bold">Kotijahti Business &rarr;</p>*/}
      {/*        </div>*/}
      {/*        <p>Better for your bottom line</p>*/}
      {/*      </div>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</main>*/}
      {/*<Footer />*/}
    </>
  )
}
