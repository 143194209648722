import { CaptureConsole, ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'

class SentryLogging {
  constructor() {
    // const environment = process.env.REACT_APP_SENTRY_RELEASE;

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://8378f6ccb2fc5f7cc0b3c71610c6f976@o457191.ingest.sentry.io/4506512879779840',
        integrations: [
          new Sentry.BrowserTracing(),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
          new CaptureConsole({ levels: ['error'] }),
          new Sentry.Dedupe(),
          new ExtraErrorData(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        environment: process.env.NODE_ENV,
        normalizeDepth: 10, // Or however deep you want your state context to be.
        // Session Replay
        // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }
  }
}

export default SentryLogging
