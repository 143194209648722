import { FaCouch } from 'react-icons/fa'
import {
  MdOutlineApartment,
  MdBedroomParent,
  MdOutlineFireplace,
  MdOutlineElevator,
  MdOutlinePets,
  MdBalcony,
} from 'react-icons/md'
import { AiOutlineEuro } from 'react-icons/ai'
import { BiArea } from 'react-icons/bi'
import { LuParkingSquare } from 'react-icons/lu'

import CategoryBox from '../CategoryBox'
import Container from '../Container'
import { useSearchParams, useLocation } from 'react-router-dom'

export const categories = [
  {
    label: 'Type',
    icon: MdOutlineApartment,
    description: 'What type of rental are you looking for?',
  },
  {
    label: 'Furnished',
    icon: FaCouch,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Price Range',
    icon: AiOutlineEuro,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Living Area',
    icon: BiArea,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Rooms',
    icon: MdBedroomParent,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Balcony',
    icon: MdBalcony,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Fireplace',
    icon: MdOutlineFireplace,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Elevator',
    icon: MdOutlineElevator,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Pets Allowed',
    icon: MdOutlinePets,
    description: 'Do you need a furnished apartment?',
  },
  {
    label: 'Parking Space',
    icon: LuParkingSquare,
    description: 'Do you need a furnished apartment?',
  },
]

const Categories = () => {
  const [params, setParams] = useSearchParams()
  const category = params.get('category')
  const pathname = useLocation()
  // const isMainPage = pathname === '/'
  //
  // if (!isMainPage) {
  //   return null
  // }

  return (
    <Container>
      <div
        className="
          pt-4
          flex 
          flex-row 
          items-center 
          justify-between
          overflow-x-auto
        "
      >
        {categories.map((item) => (
          <CategoryBox
            key={item.label}
            label={item.label}
            icon={item.icon}
            selected={category === item.label}
          />
        ))}
      </div>
    </Container>
  )
}

export default Categories
