import { create } from 'zustand'
import ReactGA from 'react-ga4'

interface SearchModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const useSearchModal = create<SearchModalStore>((set) => ({
  isOpen: false,
  onOpen: () => {
    ReactGA.event({
      category: 'navigation',
      action: 'search_modal',
      nonInteraction: false,
    })
    set({ isOpen: true })
  },
  onClose: () => {
    ReactGA.event({
      category: 'navigation',
      action: 'search_modal',
      nonInteraction: false,
    })
    set({ isOpen: false })
  },
}))

export default useSearchModal
