import { createSlice } from '@reduxjs/toolkit'

export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    value: 'fi',
  },
  reducers: {
    setLang: (state, action) => {
      state.value += action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLang } = langSlice.actions

export default langSlice.reducer
