import { useReducer } from 'react'

interface FileObj {
  fileName: string | null
  fileSize: number | null
  fileType: string | null
  fileContents: File | null
}

const initialFileState = {
  fileError: null,
  files: [],
}

function bytesToMb(bytes: number) {
  const mb = bytes / 1000000

  return mb
}

export function useFileChange() {
  const changedFiles: Array<FileObj> = []
  const [{ fileError, files }, fileDispatch] = useReducer(
    fileChangeReducer,
    initialFileState,
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files && event.target.files[0]) {
      return
    }

    for (let i = 0; i < event.target.files.length; i += 1) {
      const fileObj = event.target.files[i]
      const [type] = fileObj.type.split('/')
      if (!type || type !== 'image') {
        fileDispatch({
          type: 'FILE_CHANGE_FAILURE',
          fileError: 'You can only upload image files.',
        })
        return
      }
      if (fileObj.size > 10000000) {
        fileDispatch({
          type: 'FILE_CHANGE_FAILURE',
          fileError: `File is too large, file size is ${bytesToMb(
            fileObj.size,
          ).toFixed(2)} MB, maximum allowed size - 1 MB.`,
        })
        return
      }
      changedFiles.push({
        fileName: fileObj.name,
        fileSize: fileObj.size,
        fileType: fileObj.type,
        fileContents: fileObj,
      })
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = ''
    fileDispatch({
      type: 'FILE_CHANGE_SUCCESS',
      files: changedFiles,
    })
  }

  return {
    fileError,
    files,
    handleFileChange,
    fileDispatch,
  }
}

type FileChangeState = {
  fileError: string | null
  files: Array<FileObj>
}

type FileChangeAction =
  | {
      type: 'FILE_CHANGE_SUCCESS'
      files: Array<FileObj>
    }
  | { type: 'FILE_CHANGE_FAILURE'; fileError: string }
  | { type: 'RESET_FILE_STATE' }

export function fileChangeReducer(
  _state: FileChangeState,
  action: FileChangeAction,
): FileChangeState {
  switch (action.type) {
    case 'FILE_CHANGE_SUCCESS': {
      return {
        fileError: null,
        files: action.files,
      }
    }
    case 'FILE_CHANGE_FAILURE': {
      return {
        ...initialFileState,
      }
    }
    case 'RESET_FILE_STATE': {
      return initialFileState
    }
    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`)
    }
  }
}
