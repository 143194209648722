import { BiEuro } from 'react-icons/bi'
import Counter from './Counter'
import * as React from 'react'

interface SelectOptions {
  [key: string]: string
}

interface UniversalInputProps {
  id: string
  label: string
  subLabel?: string
  type?: string
  selectOptions?: SelectOptions
  disabled?: boolean
  formatPrice?: boolean
  errors?: object
  onChange?: any
  value?: any
  rangeType?: string
}

const UniversalInput: React.FC<UniversalInputProps> = ({
  id,
  label,
  subLabel,
  type,
  selectOptions,
  disabled,
  formatPrice,
  errors,
  onChange,
  value,
  rangeType,
}): any => {
  return (
    <div className="w-full relative">
      {type === 'number' && (
        <>
          <Counter
            title={label}
            subtitle={subLabel}
            value={value}
            onChange={onChange}
          />
        </>
      )}
      {type === 'select' && (
        <>
          <select
            id={id}
            className="px-4 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
            value={value}
            onChange={onChange}
          >
            {Object.entries(selectOptions).map((c) => (
              <option key={c[1]} value={c[1]}>
                {c[0]}
              </option>
            ))}
          </select>
        </>
      )}
      {type === 'checkbox' && (
        <>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              id={id}
              type="checkbox"
              className="sr-only peer"
              checked={value}
              onChange={onChange}
            />
            <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              {label}
            </span>
          </label>
        </>
      )}
      {type === 'range' && (
        <>
          <label
            htmlFor="large-range"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {label}
          </label>
          <input
            id={id}
            type="range"
            className="w-full h-3 bg-gray-200 rounded-lg appearance-none cursor-pointer range-lg dark:bg-gray-700"
            min="0"
            max={rangeType === 'money' ? 3000 : 200}
            step={rangeType === 'money' ? 100 : 10}
            value={value}
            onChange={onChange}
          />
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
            {rangeType === 'money' ? (
              '€0'
            ) : (
              <>
                0m<sup>2</sup>
              </>
            )}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
            {rangeType === 'money' ? (
              '€1000'
            ) : (
              <>
                50m<sup>2</sup>
              </>
            )}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-2/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
            {rangeType === 'money' ? (
              '€2000'
            ) : (
              <>
                100m<sup>2</sup>
              </>
            )}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
            {rangeType === 'money' ? (
              '€3000'
            ) : (
              <>
                200m<sup>2</sup>
              </>
            )}
          </span>
        </>
      )}
      {type === 'text' && (
        <>
          {formatPrice && (
            <BiEuro
              size={24}
              className="
            text-neutral-400
            absolute
            top-5
            left-2
          "
            />
          )}
          <input
            id={id}
            disabled={disabled}
            placeholder=" "
            type={type}
            value={value}
            onChange={onChange}
            className={`
          peer
          w-full
          p-4
          pt-6 
          font-light 
          bg-white 
          border-2
          rounded-md
          outline-none
          transition
          disabled:opacity-70
          disabled:cursor-not-allowed
          ${formatPrice ? 'pl-9' : 'pl-4'}
          ${errors && errors[id] ? 'border-red-500' : 'border-neutral-300'}
          ${
            errors && errors[id] ? 'focus:border-red-500' : 'focus:border-black'
          }
        `}
          />
          <label
            className={`
          absolute 
          text-md
          duration-150 
          transform 
          -translate-y-3 
          top-5 
          z-10 
          origin-[0] 
          ${formatPrice ? 'left-9' : 'left-4'}
          peer-placeholder-shown:scale-100 
          peer-placeholder-shown:translate-y-0 
          peer-focus:scale-75
          peer-focus:-translate-y-4
          ${errors && errors[id] ? 'text-red-500' : 'text-zinc-400'}
        `}
          >
            {label}
          </label>
        </>
      )}
    </div>
  )
}

export default UniversalInput
