//@ts-nocheck

import { toast } from 'react-hot-toast'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import React, { useMemo, useState } from 'react'
import useRentModal from '../../hooks/useRentModal'
import Modal from './Modal'
import Input from '../inputs/Input'
import Heading from '../Heading'
import { AddressAutofill } from '@mapbox/search-js-react'
import UniversalInput from '../inputs/UniversalInput'
import { createEmptyListing } from '../../../functions/listing-management'
import { useIntl } from 'react-intl'
import ImageUploader from '../inputs/ImageUploader'
import { Listing } from '../../../types'
import { useAuth0 } from '@auth0/auth0-react'
import Map from '../Map'
import { useNavigate } from 'react-router-dom'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { wait } from '@testing-library/user-event/dist/utils'

/**
 * This is the rent modal. It is used to create a new listing.
 * It is a multi-step modal, and should be ordered to make the user
 * input as much info as possible in the middle steps.
 * */
enum STEPS {
  LOCATION = 0,
  CATEGORY = 1,
  INFO = 2,
  IMAGES = 3,
  DESCRIPTION = 4,
}

const RentModal = () => {
  const intl = useIntl()
  const { user } = useAuth0()
  const navigate = useNavigate()
  const rentModal = useRentModal()

  const [step, setStep] = useState(STEPS.LOCATION)
  const [isLoading, setIsLoading] = useState(false)
  const [categoryErrors, setCategoryErrors] = useState({})
  const [address, setAddress] = useState('')
  const [coordinates, setCoordinates] = useState([0, 0])
  const [addressText, setAddressText] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [rentalType, setRentalType] = useState('indefinite')
  const [housingType, setHousingType] = useState('apartment')
  const [price, setPrice] = useState(0)
  const [livingArea, setLivingArea] = useState('')
  const [rooms, setRooms] = useState(1)
  const [balcony, setBalcony] = useState(false)
  const [fireplace, setFireplace] = useState(false)
  const [furnished, setFurnished] = useState(false)
  const [elevator, setElevator] = useState(false)
  const [pets, setPets] = useState(false)
  const [parking, setParking] = useState(false)
  const [ownSauna, setOwnSauna] = useState(false)
  const [commonSauna, setCommonSauna] = useState(false)
  const [imageSrc, setImageSrc] = useState<string[]>([])

  // console.log('listing', address, addressText, coordinates, rentalType, housingType, price, livingArea, rooms, balcony, fireplace, furnished, elevator, pets, parking, ownSauna, commonSauna, imageSrc)

  const { handleSubmit } = useForm<FieldValues>({
    defaultValues: {},
  })

  const onBack = () => {
    setStep((value) => value - 1)
  }

  const onNext = () => {
    if (step === STEPS.LOCATION && !address) {
      toast.error('Please enter a valid full address')
      return
    }
    if (step === STEPS.CATEGORY && !price) {
      toast.error('Please enter a price greater than 0')
      return
    }
    if (step === STEPS.CATEGORY && !livingArea) {
      toast.error('Please enter living area in square meters greater than 0')
      return
    }
    if (step === STEPS.IMAGES && imageSrc.length === 0) {
      toast.error('Please upload at least one image for your listing')
      return
    }

    setStep((value) => value + 1)
  }

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (step !== STEPS.DESCRIPTION) {
      return onNext()
    }
    setIsLoading(true)
    try {
      console.log('Making API call to create listing')
      // TODO:: Add validation, remaining fields, and user data
      const listing: Listing = createEmptyListing()
      listing.user_email = user.email
      listing.user_name = user.name
      listing.location = address
      listing.latitude = coordinates[1]
      listing.longitude = coordinates[0]
      listing.rental_type = rentalType
      listing.housing_type = housingType
      listing.monthly_price = price
      listing.living_area = livingArea
      listing.number_of_rooms = rooms.toString()
      listing.images = imageSrc
      listing.balcony = balcony
      listing.fireplace = fireplace
      listing.furnished = furnished
      listing.elevator = elevator
      listing.pets_allowed = pets
      listing.parking = parking
      listing.own_sauna = ownSauna
      listing.common_sauna = commonSauna
      listing.description = description
      listing.title = title
      listing.source = 'kotijahti'

      // Need to use the AWS URL of the api gateway here for development, because CORS not working for localhost
      const apiResponse = await fetch(
        `${
          process.env.NODE_ENV === 'production'
            ? 'https://kotijahti.com'
            : 'https://a1kf0q8cnh.execute-api.eu-central-1.amazonaws.com'
        }/api/listings`,
        {
          method: 'POST',
          body: JSON.stringify(listing),
          headers: { 'Content-Type': 'application/json' },
        },
      )
      const jsonResponse = await apiResponse.json()
      if (apiResponse.status !== 200) {
        console.error(jsonResponse.invalidFields)
        setCategoryErrors(jsonResponse.invalidFields)
        throw new Error(jsonResponse.message)
      }
      toast.success('Listing created!')
      console.log(jsonResponse)
      console.log(
        localizedRouteGenerator(intl, 'route.listing', jsonResponse.listingId),
      )
      wait(1000)
      navigate(
        localizedRouteGenerator(intl, 'route.listing', jsonResponse.listingId),
      )
    } catch (error: Error) {
      // TODO:: Fix CORS-error when accessing kotijahti.com api from localhost
      toast.error('Something went wrong. ' + error.message)
    }
    setIsLoading(false)
  }

  const actionLabel = useMemo(() => {
    if (step === STEPS.DESCRIPTION) {
      return 'Create'
    }

    return 'Next'
  }, [step])

  const secondaryActionLabel = useMemo(() => {
    if (step === STEPS.LOCATION) {
      return undefined
    }

    return 'Back'
  }, [step])

  let bodyContent = (
    <div className="flex flex-col gap-8">
      <div
        className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
        role="progressbar"
      >
        <div
          className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500`}
        ></div>
      </div>
      <Heading
        title="Where is your place located?"
        subtitle="Help seekers find you!"
      />
      <form autoComplete="off">
        <AddressAutofill
          className="w-full relative"
          accessToken={
            'pk.eyJ1Ijoic3BpbmFhZGJ1c2luZXNzIiwiYSI6ImNrYXdvOGw4OTA2YnoydG12OWwwajZjZ2QifQ.iFZtIDeXP459qdiXk3wmVQ'
          }
          onRetrieve={(res) => {
            console.log('retrieve', res)
            setAddress(res.features[0].properties.full_address)
            console.log(
              'address coordinates',
              res.features[0].geometry.coordinates,
            )
            setCoordinates(res.features[0].geometry.coordinates)
          }}
          options={{
            language: intl.locale,
            country: 'FI',
          }}
        >
          <input
            autoComplete="false"
            value={addressText}
            required
            onChange={(e) => setAddressText(e.target.value)}
            id={'location'}
            placeholder="Property Address"
            type={'text'}
            className="
              peer
              w-full
              p-4
              pt-6
              font-light
              bg-white
              border-2
              rounded-md
              outline-none
              transition
              disabled:opacity-70
              disabled:cursor-not-allowed
              pl-4
              border-neutral-300
              focus:border-black
            "
          />
        </AddressAutofill>
        {address && (
          <>
            <p className="mt-8 text-gray-800 dark:text-gray-500">Address:</p>
            <p className="text-right dark:text-gray-400 mb-6">{address}</p>
            <div className="h-64">
              <Map coordinates={[coordinates]} center={coordinates} />
            </div>
          </>
        )}
      </form>
    </div>
  )

  if (step === STEPS.CATEGORY) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <div
          className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 w-"
          role="progressbar"
        >
          <div
            className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500 w-1/5`}
          ></div>
        </div>
        <Heading
          title="Give us some basic information about your place..."
          subtitle="Help others understand it better!"
        />
        <UniversalInput
          id="rental_type"
          label={'Rental Type'}
          required
          errors={categoryErrors}
          type={'select'}
          selectOptions={{
            'Indefinite Rental': 'indefinite',
            'Limited Period Rental': 'limited',
            Sublet: 'sublet',
          }}
          onChange={(e) => {
            console.log(e.target.value)
            setRentalType(e.target.value)
          }}
        />
        <UniversalInput
          id="housing_type"
          label={'Housing Type'}
          errors={categoryErrors}
          required
          type={'select'}
          selectOptions={{
            Apartment: 'apartment rental',
            House: 'house rental',
            Room: 'room rental',
          }}
          onChange={(e) => {
            setHousingType(e.target.value)
          }}
        />
        <UniversalInput
          id="number_of_rooms"
          label={'Rooms'}
          subLabel={'The number of rooms'}
          errors={categoryErrors}
          required
          type={'number'}
          value={rooms}
          onChange={(changedValue: string) => {
            setRooms(parseInt(changedValue))
          }}
        />
        <hr className="my-10" />
        <UniversalInput
          id="monthly_price"
          label={'Monthly Rent'}
          formatPrice={true}
          errors={categoryErrors}
          type={'text'}
          value={price}
          required
          onChange={(e) => {
            setPrice(e.target.value)
          }}
        />
        <UniversalInput
          id="living_area"
          label={'Living Area'}
          errors={categoryErrors}
          type={'text'}
          required
          value={livingArea}
          onChange={(e) => {
            setLivingArea(e.target.value)
          }}
        />
      </div>
    )
  }

  if (step === STEPS.INFO) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <div
          className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 w-"
          role="progressbar"
        >
          <div
            className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500 w-2/5`}
          ></div>
        </div>
        <Heading
          title="More details"
          subtitle="Optional extra information to make your place stand out!"
        />
        <UniversalInput
          id="furnished"
          label={'Furnished'}
          errors={categoryErrors}
          type={'checkbox'}
          value={furnished}
          onChange={(e) => {
            setFurnished(e.target.checked)
          }}
        />
        <UniversalInput
          id="furnished"
          label={'Common Sauna'}
          errors={categoryErrors}
          type={'checkbox'}
          value={commonSauna}
          onChange={(e) => {
            setCommonSauna(e.target.checked)
          }}
        />
        <UniversalInput
          id="furnished"
          label={'Own Sauna'}
          errors={categoryErrors}
          type={'checkbox'}
          value={ownSauna}
          onChange={(e) => {
            setOwnSauna(e.target.checked)
          }}
        />
        <UniversalInput
          id="balcony"
          label={'Balcony'}
          errors={categoryErrors}
          type={'checkbox'}
          value={balcony}
          onChange={(e) => {
            setBalcony(e.target.checked)
          }}
        />
        <UniversalInput
          id="fireplace"
          label={'Fireplace'}
          errors={categoryErrors}
          type={'checkbox'}
          value={fireplace}
          onChange={(e) => {
            setFireplace(e.target.checked)
          }}
        />
        <UniversalInput
          id="elevator"
          label={'Elevator'}
          value={elevator}
          errors={categoryErrors}
          type={'checkbox'}
          onChange={(e) => {
            setElevator(e.target.checked)
          }}
        />
        <UniversalInput
          id="pets"
          label={'Pets Allowed'}
          errors={categoryErrors}
          type={'checkbox'}
          value={pets}
          onChange={(e) => {
            setPets(e.target.checked)
          }}
        />
        <UniversalInput
          id="parking"
          label={'Parking Garage'}
          errors={categoryErrors}
          type={'checkbox'}
          value={parking}
          onChange={(e) => {
            setParking(e.target.checked)
          }}
        />
      </div>
    )
  }

  if (step === STEPS.IMAGES) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <div
          className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 w-"
          role="progressbar"
        >
          <div
            className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500 w-3/5`}
          ></div>
        </div>
        <Heading
          title="Add some photos of your place"
          subtitle="Show renters what your place looks like!"
        />
        <ImageUploader
          onChange={(value) => {
            setImageSrc(value)
          }}
          value={imageSrc}
        />
      </div>
    )
  }

  if (step === STEPS.DESCRIPTION) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <div
          className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700 w-"
          role="progressbar"
        >
          <div
            className={`flex flex-col justify-center rounded-full overflow-hidden bg-blue-500 text-xs text-white text-center whitespace-nowrap transition duration-500 w-4/5`}
          ></div>
        </div>
        <Heading
          title="How would you describe your place?"
          subtitle="Short and sweet works best!"
        />
        <UniversalInput
          id="title"
          label="Title"
          type={'text'}
          value={title}
          disabled={isLoading}
          errors={categoryErrors}
          required
          onChange={(e) => {
            setTitle(e.target.value)
          }}
        />
        <hr />
        <UniversalInput
          id="description"
          label="Description"
          type={'text'}
          value={description}
          disabled={isLoading}
          errors={categoryErrors}
          required
          onChange={(e) => {
            setDescription(e.target.value)
          }}
        />
      </div>
    )
  }

  return (
    <Modal
      disabled={isLoading}
      isOpen={rentModal.isOpen}
      title="Rent or sublet your home!"
      actionLabel={actionLabel}
      onSubmit={handleSubmit(onSubmit)}
      secondaryActionLabel={secondaryActionLabel}
      secondaryAction={step === STEPS.LOCATION ? undefined : onBack}
      onClose={rentModal.onClose}
      body={bodyContent}
    />
  )
}

export default RentModal
