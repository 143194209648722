import { useMemo } from 'react'
import { User } from '@auth0/auth0-spa-js'

interface IUseFavorite {
  listingId: string
  currentUser?: User | null
}

const useFavorite = ({ listingId, currentUser }: IUseFavorite) => {
  const hasFavorited = useMemo(() => {
    const list = currentUser?.favoriteIds || []

    return list.includes(listingId)
  }, [currentUser, listingId])

  const toggleFavorite = () => {}

  // const toggleFavorite = useCallback(
  //   async (e: React.MouseEvent<HTMLDivElement>) => {
  //     e.stopPropagation()
  //
  //     if (!currentUser) {
  //       return loginModal.onOpen()
  //     }
  //
  //     try {
  //       let request
  //
  //       if (hasFavorited) {
  //         request = () => axios.delete(`/api/favorites/${listingId}`)
  //       } else {
  //         request = () => axios.post(`/api/favorites/${listingId}`)
  //       }
  //
  //       await request()
  //       router.refresh()
  //       toast.success('Success')
  //     } catch (error) {
  //       toast.error('Something went wrong.')
  //     }
  //   },
  //   [currentUser, hasFavorited, listingId, router],
  // )

  return {
    hasFavorited,
    toggleFavorite,
  }
}

export default useFavorite
