import React from 'react'
import Home from './Home'
import { Routes, Route } from 'react-router-dom'
import Business from './Business'
import About from './About'
import Listing from './Listing'
import { defineMessages, useIntl } from 'react-intl'
import NoLang from './NoLang'
import { localizedRouteGenerator } from '../languages/routes/routeGenerator'
import ProfileOverview from './ProfileOverview'
import AuthCallback from './AuthCallback'
import { default as ChannelListDesktop } from './messenger/DesktopLayout/ChannelList'
import { default as ChannelListMobile } from './messenger/MobileLayout/ChannelList'
import { default as ChannelDesktop } from './messenger/DesktopLayout/Channel'
import { default as ChannelMobile } from './messenger/MobileLayout/Channel'
import { default as ChannelSettingsDesktop } from './messenger/DesktopLayout/ChannelSettings'
import { default as ChannelSettingsMobile } from './messenger/MobileLayout/ChannelSettings'
import { default as MessageSearchDesktop } from './messenger/DesktopLayout/MessageSearch'
import { default as MessageSearchMobile } from './messenger/MobileLayout/MessageSearch'
import Properties from './Properties'

const routeMessages = defineMessages({
  homeRoute: {
    id: 'route.home',
    description: 'Home page route',
    defaultMessage: '/',
  },
  listingRoute: {
    id: 'route.listing',
    description: 'About listing route',
    defaultMessage: '/listing',
  },
  propertiesRoute: {
    id: 'route.properties',
    description: 'Where the user can view their own listings',
    defaultMessage: '/properties',
  },
  favouritesRoute: {
    id: 'route.favourites',
    description: 'Where users can view their favourited listings',
    defaultMessage: '/favourites',
  },
  savedSearchesRoute: {
    id: 'route.savedSearches',
    description: 'Where users can view their previous searches',
    defaultMessage: '/saved-searches',
  },
  businessRoute: {
    id: 'route.business',
    description: 'Business page route',
    defaultMessage: '/business',
  },
  aboutRoute: {
    id: 'route.about',
    description: 'About page route',
    defaultMessage: '/about',
  },
  profileOverviewRoute: {
    id: 'route.profileOverview',
    description: 'All about the logged-in tray',
    defaultMessage: '/user-profile-overview',
  },
  authCallbackRoute: {
    id: 'route.authCallback',
    description: 'Where the authentication returns to after',
    defaultMessage: '/callback',
  },
  // TODO:: Cleanup messenger URLs to make more sense
  // TODO:: Actually implement correct routes for the mobile versions of the messenger
  messengerRoute: {
    id: 'route.messenger',
    description: 'Where you can access the messenger',
    defaultMessage: '/messages',
  },
  channelsRoute: {
    id: 'route.channels',
    description: 'Where you can access the messenger specific channels',
    defaultMessage: '/messages/channels',
  },
  channelSearchRoute: {
    id: 'route.channelSettings',
    description: 'Where you can search the messenger channels',
    defaultMessage: '/messages/channels/settings',
  },
  channelSettings: {
    id: 'route.channelSearch',
    description: 'Where you can access the messenger channel settings',
    defaultMessage: '/messages/channels/search',
  },
})
// const usePathPattern = (): string | undefined => {
//     const { pathname } = useLocation();
//     return routeMessages.find((route) => matchPath(route.defaultMessage, pathname));
// };

function RoutesCenter() {
  const intl = useIntl()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  return (
    <Routes>
      <Route path="*" element={<NoLang />} />
      <Route
        path={localizedRouteGenerator(intl, 'route.home')}
        element={<Home />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.properties')}
        element={<Properties />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.favourites')}
        element={<Home />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.business')}
        element={<Business />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.about')}
        element={<About />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.listing', ':listingId')}
        element={<Listing />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.profileOverview')}
        element={<ProfileOverview />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.authCallback')}
        element={<AuthCallback />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.messenger')}
        element={isMobile ? <ChannelListMobile /> : <ChannelListDesktop />}
      />
      <Route
        path={localizedRouteGenerator(intl, 'route.channels', ':channelUrl')}
        element={isMobile ? <ChannelMobile /> : <ChannelDesktop />}
      />
      <Route
        path={localizedRouteGenerator(
          intl,
          'route.channelSettings',
          ':channelUrl',
        )}
        element={
          isMobile ? <ChannelSettingsMobile /> : <ChannelSettingsDesktop />
        }
      />
      <Route
        path={localizedRouteGenerator(
          intl,
          'route.channelSearch',
          ':channelUrl',
        )}
        element={isMobile ? <MessageSearchMobile /> : <MessageSearchDesktop />}
      />
    </Routes>
  )
}

export default RoutesCenter
