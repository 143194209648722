import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import '@sendbird/uikit-react/dist/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth0ProviderWithNavigate } from './auth/auth0-provider-with-navigate'
import SentryLogging from './functions/errorLogging'

new SentryLogging()
async function bootstrapApplication() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )

  root.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <Router>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </Router>
      </ReduxProvider>
    </React.StrictMode>,
  )

  // If you want to start measuring performance in your components, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

bootstrapApplication()
