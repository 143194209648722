const API_BASE_URL = 'https://a1kf0q8cnh.execute-api.eu-central-1.amazonaws.com'

type PresignedPostUrlResponse = {
  url: string
  fields: {
    key: string
    acl: string
    bucket: string
  }
  filePath: string
}

export async function uploadToS3({
  fileType,
  fileContents,
}: {
  fileType: string
  fileContents: File
}) {
  const presignedPostUrl: PresignedPostUrlResponse = await getPresignedPostUrl(
    fileType,
  )

  const formData = new FormData()
  formData.append('Content-Type', fileType)
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v)
  })
  formData.append('file', fileContents) // The file has be the last element

  const response = await fetch(presignedPostUrl.url, {
    method: 'POST',
    body: formData,
  })

  return presignedPostUrl.filePath
}

const GET_PRESIGNED_URL_API_PATH = 'get-presigned-url-s3'

async function getPresignedPostUrl(fileType: string) {
  const data: Response = await fetch(
    `${API_BASE_URL}/${GET_PRESIGNED_URL_API_PATH}?fileType=${fileType}`,
  )

  return await data.json()
}
