import SbChannelList from '@sendbird/uikit-react/ChannelList'
import { useNavigate } from 'react-router-dom'
import RootLayout from '../../layout'
import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import MobileNav from '../../../components/components/navbar/MobileNav'

export default function ChannelList() {
  const navigate = useNavigate()
  const { user } = useAuth0()
  return (
    <RootLayout withCategories={false}>
      <div className="h-[calc(100vh-200px)]">
        <SbChannelList
          disableAutoSelect
          onChannelSelect={(channel) => {
            navigate(`/channels/${channel.url}`)
          }}
        />
      </div>
      <MobileNav />
    </RootLayout>
  )
}
