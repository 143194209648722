import { IntlShape } from 'react-intl'

export const localizedRouteGenerator = (
  intl: IntlShape,
  route: string,
  id?: string,
  paramsString?: string,
): string => {
  const descriptor = { id: route }

  return `/${intl.locale}${intl.formatMessage(descriptor)}${
    id ? '/' + id : ''
  }${paramsString ? '?' + paramsString : ''}`
}
