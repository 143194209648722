'use client'

import { useNavigate } from 'react-router-dom'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { useIntl } from 'react-intl'

const Logo = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  return (
    <img
      onClick={() => navigate(localizedRouteGenerator(intl, 'route.home'))}
      className="hidden md:block cursor-pointer"
      src="/kotijahti_logo_temporary.png"
      height="100"
      width="100"
      alt="Logo"
    />
  )
}

export default Logo
