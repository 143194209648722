import SbChannel from '@sendbird/uikit-react/Channel'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'

import { useNavigateOnBan } from '../hooks/useNavigateOnBan'
import MobileNav from '../../../components/components/navbar/MobileNav'
import * as React from 'react'

export default function Channel() {
  const { channelUrl } = useParams()
  const [searchParams] = useSearchParams()
  const messageId = searchParams.get('messageId')
  const createdAt = searchParams.get('createdAt')
  const numCreatedAt = Number(createdAt)
  const navigate = useNavigate()
  useNavigateOnBan()
  return (
    <div className="h-[calc(100vh-200px)]">
      <SbChannel
        channelUrl={channelUrl as string}
        onBackClick={() => {
          navigate('/')
        }}
        onSearchClick={() => {
          navigate(`/channels/${channelUrl}/search`)
        }}
        {...(messageId &&
          typeof numCreatedAt === 'number' && {
            startingPoint: numCreatedAt,
            highlightedMessageId: messageId,
            animatedMessageId: messageId,
          })}
        showSearchIcon
        onChatHeaderActionClick={() => {
          navigate(`/channels/${channelUrl}/settings`)
        }}
      />
      <MobileNav />
    </div>
  )
}
