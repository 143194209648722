import { User } from '@auth0/auth0-spa-js'

import Categories from './Categories'
import Container from '../Container'
import Logo from './Logo'
import Search from './Search'
import UserMenu from './UserMenu'
import { useAuth0 } from '@auth0/auth0-react'

interface NavBarProps {
  currentUser?: User | null
  withCategories?: boolean
}

const NavBar: React.FC<NavBarProps> = ({ currentUser, withCategories }) => {
  const { user } = useAuth0()
  return (
    <div className="fixed w-full bg-white z-10 shadow-sm">
      <div
        className="
          py-4 
          border-b-[1px]
        "
      >
        <Container>
          <div
            className="
            flex 
            flex-row 
            items-center 
            justify-between
            gap-3
            md:gap-0
          "
          >
            <Logo />
            <Search />
            <UserMenu currentUser={user} />
          </div>
        </Container>
      </div>
      {/* TODO:: Change the categories at the topic to be "common" searches like sublets, or apartments downtown, or detached suburban houses, on metro line, easy commutes, etc.*/}
      {/* TODO:: Change the categories where it IS enabled to be collapsible and centrally saved */}
      {withCategories ? <Categories /> : null}
    </div>
  )
}

export default NavBar
