import SbChannel from '@sendbird/uikit-react/Channel'
import SbChannelList from '@sendbird/uikit-react/ChannelList'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useNavigateOnBan } from '../hooks/useNavigateOnBan'
import RootLayout from '../../layout'
import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { localizedRouteGenerator } from '../../../languages/routes/routeGenerator'
import { IntlShape, useIntl } from 'react-intl'
import MobileNav from '../../../components/components/navbar/MobileNav'

export default function ChannelList() {
  const navigate = useNavigate()
  const { channelUrl } = useParams()
  const intl: IntlShape = useIntl()
  const { user } = useAuth0()

  useNavigateOnBan()

  return (
    <RootLayout withCategories={false}>
      <div className="h-[calc(100vh-200px)] grid">
        <div className="grid-cols-1">
          <SbChannelList
            disableAutoSelect={true}
            disableUserProfile={true}
            allowProfileEdit={false}
            onChannelSelect={(channel) => {
              navigate(
                localizedRouteGenerator(intl, 'route.channels', channel.url),
              )
            }}
          />
        </div>
        {
          // An empty channel!
          // You might want to implement a custom placeholder instead
          !channelUrl && <SbChannel channelUrl="" />
        }
        <Outlet />
      </div>
      <MobileNav />
    </RootLayout>
  )
}
